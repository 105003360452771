import { I18n } from 'i18n-js';

import cs from './cs.json';
import de from './de.json';
import en from './en.json';

class MyI18n {
  constructor() {
    this.i18n = new I18n({ ...cs, ...de, ...en });

    if (gon?.locale) {
      this.i18n.locale = gon.locale;
    }
  }
}

const myI18n = new MyI18n();
export default myI18n.i18n;
