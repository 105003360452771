import capitalizeFirstLetter from 'plugins/string/capitalize_first_letter';

function defineTargetValuesFromDataset(target, prefix, data) {
  const result = {};

  for (const key of Object.keys(data)) {
    const keyData = data[key];
    const normalizedKey = `${prefix}${capitalizeFirstLetter(key)}`;

    Object.defineProperty(result, key, {
      get() {
        if (!(normalizedKey in target.dataset)) {
          if ('default' in keyData) {
            return keyData.default;
          }

          if (keyData.required) {
            console.error(`Undefined value: ${key} for target:`, target);
          }

          return undefined;
        }

        if (keyData.type === Boolean) {
          return target.dataset[normalizedKey] === 'true';
        }

        if (keyData.type === Number) {
          return Number(target.dataset[normalizedKey]);
        }

        if (keyData.type === Object) {
          return JSON.parse(target.dataset[normalizedKey]);
        }

        return target.dataset[normalizedKey];
      },
      set(value) {
        target.dataset[normalizedKey] = value;
      },
      enumerable: true,
      configurable: true,
    });
  }

  return result;
}

export default defineTargetValuesFromDataset;
