function defineProps(context, data) {
  const values = {};

  for (const key of Object.keys(data)) {
    const keyData = data[key];

    Object.defineProperty(context, key, {
      get() {
        if (!(key in values)) {
          if ('default' in keyData) {
            return keyData.default;
          }

          return undefined;
        }

        return values[key];
      },
      set(value) {
        values[key] = value;
      },
      enumerable: true,
      configurable: true,
    });
  }

  return context;
}

export default defineProps;
