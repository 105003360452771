function isInsideDialog(target) {
  let element = target;

  while (element.parentNode) {
    if (element.parentNode.tagName === 'DIALOG') {
      return true;
    }

    element = element.parentNode;
  }

  return false;
}

export default isInsideDialog;
