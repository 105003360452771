import ApplicationController from 'modules/application_controller';
import addListener from 'plugins/utilities/add_listener';
import removeListener from 'plugins/utilities/remove_listener';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      listeners: [],
    };
  }

  connect() {
    this.listeners.push(addListener(window, 'resize', this.updateWindowSize.bind(this)));
    this.updateWindowSize();
  }

  disconnect() {
    for (const listenerId of this.listeners) {
      removeListener(window, { id: listenerId });
    }
  }

  updateWindowSize() {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--window-inner-width', `${window.innerWidth}px`);
  }

  get listeners() {
    return this.props.listeners;
  }

  set listeners(value) {
    this.props.listeners = value;
  }
}
