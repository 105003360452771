function slideInAnimationClasses(placement) {
  if (!placement) return [];

  const side = placement.split('-')[0];
  let animation = [];

  switch (side) {
    case 'top':
      animation = ['[&:not(.hiding)]:tw-animate-slide-up-0.5', '[&.hiding]:tw-animate-reverse-slide-up-0.5'];
      break;
    case 'bottom':
      animation = ['[&:not(.hiding)]:tw-animate-slide-down-0.5', '[&.hiding]:tw-animate-reverse-slide-down-0.5'];
      break;
    case 'left':
      animation = ['[&:not(.hiding)]:tw-animate-slide-left-0.5', '[&.hiding]:tw-animate-reverse-slide-left-0.5'];
      break;
    case 'right':
      animation = ['[&:not(.hiding)]:tw-animate-slide-right-0.5', '[&.hiding]:tw-animate-reverse-slide-right-0.5'];
      break;
    default:
      break;
  }

  return animation;
}

export default slideInAnimationClasses;
